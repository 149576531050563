import React from "react";

export default function HorizontalCardList({ className, title, viewAllUrl, children }) {
  return (
    <>
      <section
        className={`flex overflow-x-auto gap-4 scrollbar-hide 
            max-w-auto xl:max-w-[1200px] 
            mx-0 xl:mx-auto
            snap-x
            ${className}
            hide-scroll-bar
            -mr-4
        `}
      >
        {React.Children.map(children, (child) => (
          <div
            className="flex-none
                     first:ml-4 lg:first:ml-8 xl:first:ml-0
                     last:mr-4 lg:last:mr-8 xl:last:mr-0 snap-start
                     overscroll-contain
                     "
          >
            {child}
          </div>
        ))}
      </section>
      <style>{`
        .hide-scroll-bar {
        -ms-overflow-style: none;
        scrollbar-width: none;
        }
        .hide-scroll-bar::-webkit-scrollbar {
        display: none;
        }    
    `}</style>
    </>
  );
}
