import { Suspense } from "react";
import { Await, ErrorResponse, MetaArgs, useLoaderData, useRouteLoaderData } from "react-router";
import { LoaderFunctionArgs } from "react-router";
import PlayCard from "~/components/PlayCard";
import Container from "~/components/shared/Container";
import Header from "./Header";
import { getLocationFromIp } from "~/services/location.server";
import RadarLocationSearchModal from "~/components/shared/RadarLocationSearchModal";
import HorizontalCardList from "~/components/HorizontalCardList";
import { getNearbyUniqueSessions } from "~/models/Session";
import { getRecentlyAddedPlays, getTopRatedPlays } from "~/models/Play";
import { playPath } from "~/services/url-builder";

export async function loader({ request }: LoaderFunctionArgs) {
  const location = getLocationFromIp(request);
  const nearbySessions = getNearbyUniqueSessions(location?.coords.lat, location?.coords.lon).then((a) => a);
  const recentlyAddedPlays = getRecentlyAddedPlays().then((p) => p);
  const topRatedPlays = getTopRatedPlays().then((p) => p);

  return { nearbySessions, location, recentlyAddedPlays, topRatedPlays };
}

export default function IndexRoute() {
  const { nearbySessions, location, recentlyAddedPlays, topRatedPlays } = useLoaderData<typeof loader>();
  const { isAuthenticated } = useRouteLoaderData("root");

  return (
    <>
      <Header isAuthenticated={isAuthenticated} />
      <Container className="py-20 flex flex-col gap-8">
        <NearbySessions location={location} nearbySessions={nearbySessions} />

        {/* <TopRatedPlaysSection topRatedPlays={topRatedPlays} /> */}

        <RecentlyAddedPlaysSection recentlyAddedPlays={recentlyAddedPlays} />

        {/* <SignupPromotionSection /> */}

        {/* <NewsletterSection /> */}
      </Container>
    </>
  );
}

function NearbySessions({ location, nearbySessions }) {
  return (
    <div className="mb-16">
      <div className="flex flex-row gap-2 items-center flex-wrap mb-8">
        <h2 className="text-xl text-gray-500 font-semibold">Espetáculos perto de</h2>
        <RadarLocationSearchModal locationName={location.name} size="small" />
      </div>

      <Suspense fallback={<SectionLoading />}>
        <Await resolve={nearbySessions} errorElement={<NearbySessionsError />}>
          {(nearbySessions) =>
            nearbySessions.length ? (
              <HorizontalCardList>
                {nearbySessions.slice(0, 6).map((session) => (
                  <PlayCard
                    key={session.play.id}
                    href={playPath(session.play)}
                    title={session.play.title}
                    imageUrl={session.play.images?.[0].publicUrl}
                    location={session.venue.name}
                    datetime={session.datetime}
                  />
                ))}
              </HorizontalCardList>
            ) : (
              <SectionEmpty message="Não encontramos espetáculos perto de ti :(" />
            )
          }
        </Await>
      </Suspense>
    </div>
  );
}

function NearbySessionsError({ error }: { error: ErrorResponse }) {
  return (
    <div className="bg-gray-50 border rounded flex flex-col items-center justify-center min-h-[270px]">
      <p className="font-semibold text-gray-600">
        Ocorreu um erro ao carregar as peças mas podes encontrar as peças diretamente no Radar.
      </p>
    </div>
  );
}

function RecentlyAddedPlaysSection({ recentlyAddedPlays }) {
  return (
    <div className="mb-16">
      <Title>Última peças adicionadas</Title>

      <Suspense fallback={<SectionLoading />}>
        <Await resolve={recentlyAddedPlays} errorElement={<SectionError />}>
          {(recentlyAddedPlays) => <SectionList plays={recentlyAddedPlays} />}
        </Await>
      </Suspense>
    </div>
  );
}

function Title({ children }) {
  return <h2 className="text-xl text-gray-500 font-semibold mb-8">{children}</h2>;
}

function TopRatedPlaysSection({ topRatedPlays }) {
  return (
    <div className="mb-10">
      <Title>Peças com melhor pontuação</Title>

      <Suspense fallback={<SectionLoading />}>
        <Await resolve={topRatedPlays} errorElement={<SectionError />}>
          {(topRatedPlays) => <SectionList plays={topRatedPlays} />}
        </Await>
      </Suspense>
    </div>
  );
}

function SectionList({ plays }) {
  if (!plays?.length) {
    return <SectionEmpty />;
  }

  return (
    <HorizontalCardList>
      {plays.map((play) => (
        <PlayCard
          key={play.id}
          href={playPath(play)}
          title={play.title}
          imageUrl={play.images?.[0]?.publicUrl}
          datetime={play.sessions?.[0]?.datetime}
          attribution={
            !!play.averageRating && (
              <div>
                <span className="font-semibold text-gray-800">⭐️ {play.averageRating}</span>{" "}
                <span className="text-gray-400 font-normal">({play.totalRatings} votos)</span>
              </div>
            )
          }
        />
      ))}
    </HorizontalCardList>
  );
}

function SectionEmpty({ message = "Oops!" }) {
  return (
    <div className="bg-gray-50 border rounded flex flex-col items-center justify-center min-h-[270px]">
      <p className="font-semibold text-gray-400">{message}</p>
    </div>
  );
}

function SectionLoading() {
  return (
    <div className="bg-gray-50 border rounded flex flex-col items-center justify-center min-h-[270px]">
      <p className="font-semibold text-gray-400">Aguarde...</p>
    </div>
  );
}

function SectionError({ error }: { error: ErrorResponse }) {
  console.log("HIT"); // For debugging
  console.error("Section error:", error); // For debugging

  return (
    <div className="bg-gray-50 border rounded flex flex-col items-center justify-center min-h-[270px]">
      <p className="font-semibold text-red-800 text-center">
        Ocorreu um erro ao carregar as sessões.
        <br />
        Por favor, tenta novamente mais tarde.
      </p>
    </div>
  );
}

export function meta({ data }: MetaArgs) {
  const title = "Teatro.app";
  const description = "Descobre as peças de teatro perto de ti!";
  return [
    { title },
    { name: "description", content: description },
    {
      name: "keywords",
      content:
        "peças de teatro, teatro, espetáculos, bilhetes, portugal, perto de mim, teatro lisboa, teatro porto, teatro coimbra, teatro portugal",
    },
    { name: "author", content: "Fernando Araújo" },
    { name: "creator", content: "Fernando Araújo" },

    // OpenGraph tags
    { property: "og:title", content: title },
    { property: "og:description", content: description },
    { property: "og:url", content: "https://teatro.app" },
    { property: "og:site_name", content: "Teatro.app" },
    { property: "og:image", content: "https://teatro.app/teatro-og.png" },
    { property: "og:image:width", content: "1500" },
    { property: "og:image:height", content: "630" },
    { property: "og:image:alt", content: "Teatro Logo" },
    { property: "og:locale", content: "pt_PT" },
    { property: "og:type", content: "website" },
  ];
}
