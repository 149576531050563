import { Link } from "react-router";
import { Button } from "~/components/ui/button";
import { ROUTES } from "~/services/const";

export default function Header({ isAuthenticated }: { isAuthenticated: boolean }) {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
      <svg
        viewBox="0 0 1024 1024"
        className="absolute left-1/2 top-1/2 lg:top-1/4 -z-10 h-[40rem] w-[40rem] lg:h-[72rem] lg:w-[72rem] [mask-image:radial-gradient(closest-side,white,transparent)]  ml-0 -translate-x-1/2 translate-y-0"
        aria-hidden="true"
      >
        <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
        <defs>
          <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
            <stop stopColor="#E00606" />
            <stop offset="1" stopColor="#A21A1C" />
          </radialGradient>
        </defs>
      </svg>

      <div className="max-w-lg lg:max-w-xl mx-auto  text-center lg:flex-auto pb-16 lg:pt-48 lg:pb-40">
        <h2 className="text-balance text-5xl font-semibold tracking-tight text-white sm:text-7xl mb-10">
          Bem-vindo ao Teatro 🎭
        </h2>
        <p className="mt-6 text-pretty text-lg text-gray-200 ">
          Desde estreias locais a peças consagradas, descobre todos os espetáculos de teatro em Portugal.
        </p>

        <div className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-4">
          {!isAuthenticated && (
            <>
              <Button
                asChild
                variant="outline"
                className="min-w-[240px] sm:min-w-0 bg-transparent text-white hover:bg-gray-100"
              >
                <Link to={ROUTES.SIGNUP} className="">
                  Criar conta
                </Link>
              </Button>
            </>
          )}

          {!!isAuthenticated && (
            <Button
              asChild
              variant="outline"
              className="min-w-[240px] sm:min-w-0 bg-transparent text-white hover:bg-gray-100"
            >
              <Link to={ROUTES.DASHBOARD} className="">
                A minha conta
              </Link>
            </Button>
          )}

          <Button asChild className="min-w-[240px] sm:min-w-0 text-gray-800 hover:bg-gray-100">
            <Link to={ROUTES.RADAR} className="text-sm/6 font-semibold  bg-white">
              Procurar Espetáculos <span aria-hidden="true">→</span>
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
}
